






import { Component, Vue } from 'vue-property-decorator';
import UserList from './components/UserList.vue';

@Component({
  name: 'UserManage',
  components: {
    UserList,
  },
})
export default class extends Vue {
}
